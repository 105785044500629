@import "~@/erp/styles/variables/variables.scss";




































































































































































.mail-detail-attach {
  padding: 20px 20px 0;
  box-sizing: border-box;

  &>div.attach-title {
    i {
      color: #fa6400;
      font-size: 14px;
    }

    &>span {
      font-size: 14px;
      font-weight: bold;
      color: #303030;
      margin: 0 10px;
    }
  }

  &>div.file-wrapper {
    display: flex;
    flex-wrap: wrap;

    .file-item {
      display: flex;
      align-items: center;
    }

    &>div {
      padding: 0 12px;
      background: #f5f7fa;
      border-radius: 3px;
      border: 1px solid #ebeef5;
      font-size: 12px;
      margin-right: 10px;
      line-height: 30px;
      margin-bottom: 10px;
      cursor: pointer;
      width: 200px;
      display: flex;

      i {
        color: #fa6400;
        font-size: 12px;
      }

      .title {
        flex: 1;
        width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #303030;
        line-height: 30px;
        margin-left: 10px;
      }

      .preview {
        color: #1890ff;
        margin-left: 10px;
      }

      .size {
        color: #777;
        margin-left: 10px;
      }
    }
  }
}

.fs16 {
  font-size: 16px !important;
}

.ml10 {
  margin-left: 10px;
}



.el-icon-loading {
  color: #1890ff !important;
}

::deep .el-loading-spinner {
  margin-top: -14px !important;
}

@import "~@/erp/styles/variables/variables.scss";
























































































.tip {
  font-size: 14px;
  color: #999;
}
